import { hasSufficientPerformance, hasSufficientDownloadPerformance, setup } from 'nuxt-speedkit/utils/performance';
import { isSupportedBrowser } from 'nuxt-speedkit/utils/browser';

function getMaxIdleTries () {
  let duration = 16;
  if (window.__NUXT_SPEEDKIT_MAX_IDLE_DURATION__ !== undefined) {
    duration = window.__NUXT_SPEEDKIT_MAX_IDLE_DURATION__;
  }
  return duration * 60;
}

const MAX_IDLE_TRIES = getMaxIdleTries();

let init = false
const layerEl = global.document.getElementById('nuxt-speedkit-layer');

async function initApp(force) {
  if (init) {
    return;
  }

  document.documentElement.classList.remove('nuxt-speedkit-reduced-view');

  init = force || await new Promise(resolve => waitForIdle(resolve));
  if (init) {
    return import('../client');
  }

  return null;
};

let idleTries = 0;
function waitForIdle (cb) {
  if (!!layerEl && idleTries >= MAX_IDLE_TRIES) {
    // User must interact via the layer.
    updateSpeedkitLayerMessage('nuxt-speedkit-message-weak-hardware');
    cb(false);
  } else if ('requestIdleCallback' in global &&  MAX_IDLE_TRIES > idleTries) {
    idleTries++
    global.requestIdleCallback((deadline) => {
      const time = deadline.timeRemaining();
      if (time > 10 || deadline.didTimeout) {
        cb(true);
      } else {
        waitForIdle(cb);
      }
    }, { timeout: 2000 });
  } else {
    cb(true);
  }
};

function observeSpeedkitButton (id, callback) {
  Array.from(document.querySelectorAll(`#${id}`)).forEach(el => {
    el.addEventListener('click', callback, { capture: true, once: true, passive: true })
  })
}

function updateSpeedkitLayerMessage(id) {
  const el = global.document.getElementById(id)
  if (!el) {
    throw new Error(`Can\'t update speedkit-layer, message ${id} missing.`);
  } else {
    el.style.display = 'block'
    layerEl.className += ' nuxt-speedkit-layer-visible';
  }
}

function initReducedView () {
  document.documentElement.classList.add('nuxt-speedkit-reduced-view');

  // activate fonts
  global.document.querySelectorAll('[data-font]').forEach((node) => {
    node.classList.add('font-active');
  })

  // transform noscript>picture to picture
  Array.from(document.querySelectorAll('noscript.nuxt-speedkit-picture-noscript')).forEach(el => {
    const tmp = document.createElement('div');
    tmp.innerHTML = el.innerHTML;
    el.parentNode.replaceChild(tmp.children[0], el);
    tmp.remove();
  })
}

function setupSpeedkitLayer(supportedBrowser) {
  if(!supportedBrowser) {
    updateSpeedkitLayerMessage('nuxt-speedkit-message-unsupported-browser');
  }
  if(!hasSufficientDownloadPerformance()) {
    updateSpeedkitLayerMessage('nuxt-speedkit-message-reduced-bandwidth');
  }
}

const supportedBrowser = isSupportedBrowser({regex: new RegExp("((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(12[_.]2|12[_.]([3-9]|\u005Cd{2,})|12[_.]5|12[_.]([6-9]|\u005Cd{2,})|(1[3-9]|[2-9]\u005Cd|\u005Cd{3,})[_.]\u005Cd+|14[_.]0|14[_.]([1-9]|\u005Cd{2,})|14[_.]4|14[_.]([5-9]|\u005Cd{2,})|14[_.]8|14[_.](9|\u005Cd{2,})|(1[5-9]|[2-9]\u005Cd|\u005Cd{3,})[_.]\u005Cd+|15[_.]2|15[_.]([3-9]|\u005Cd{2,})|(1[6-9]|[2-9]\u005Cd|\u005Cd{3,})[_.]\u005Cd+)(?:[_.]\u005Cd+)?)|(Opera Mini(?:\u005C\u002Fatt)?\u005C\u002F?(\u005Cd+)?(?:\u005C.\u005Cd+)?(?:\u005C.\u005Cd+)?)|(Opera\u005C\u002F.+Opera Mobi.+Version\u005C\u002F(64|(6[5-9]|[7-9]\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+)|(Opera\u005C\u002F(64|(6[5-9]|[7-9]\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+.+Opera Mobi)|(Opera Mobi.+Opera(?:\u005C\u002F|\u005Cs+)(64|(6[5-9]|[7-9]\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+)|((?:Chrome).*OPR\u005C\u002F(87|(8[8-9]|9\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+\u005C.\u005Cd+)|(SamsungBrowser\u005C\u002F(16|(1[7-9]|[2-9]\u005Cd|\u005Cd{3,}))\u005C.\u005Cd+)|(Edge\u005C\u002F(102|(10[3-9]|1[1-9]\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,}))(?:\u005C.\u005Cd+)?)|((Chromium|Chrome)\u005C\u002F(101|(10[2-9]|1[1-9]\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,}))\u005C.\u005Cd+(?:\u005C.\u005Cd+)?)|(Version\u005C\u002F(15\u005C.4|15\u005C.([5-9]|\u005Cd{2,})|(1[6-9]|[2-9]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+)(?:\u005C.\u005Cd+)? Safari\u005C\u002F)|(Firefox\u005C\u002F(91|(9[2-9]|\u005Cd{3,})|101|(10[2-9]|1[1-9]\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,}))\u005C.\u005Cd+\u005C.\u005Cd+)|(Firefox\u005C\u002F(91|(9[2-9]|\u005Cd{3,})|101|(10[2-9]|1[1-9]\u005Cd|[2-9]\u005Cd\u005Cd|\u005Cd{4,}))\u005C.\u005Cd+(pre|[ab]\u005Cd+[a-z]*)?)", "")});

if (!document.getElementById('nuxt-speedkit-layer')) {
  initApp();
} else {
  observeSpeedkitButton('nuxt-speedkit-button-init-reduced-view', initReducedView);
  observeSpeedkitButton('nuxt-speedkit-button-init-app', () => initApp(true));

  setup({"timing":{"fcp":800,"dcl":1200}});

  if(('__NUXT_SPEEDKIT_AUTO_INIT__' in global && global.__NUXT_SPEEDKIT_AUTO_INIT__) || ((true && hasSufficientPerformance()) && supportedBrowser)) {
    initApp();
  } else {
    setupSpeedkitLayer(supportedBrowser)
  }
}
